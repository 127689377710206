<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="$router.push({name: 'produto'})" />
            </div>
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="products" responsive-layout="scroll"
                   dataKey="id" :paginator="true"
                   :rows="10" :filters="filters"
                   :lazy="true"
                   :loading="loading"
                   :total-records="totalRecords"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Total {totalRecords} produtos"
                   @page="findAll($event)"
                   responsiveLayout="scroll">
          <template #empty>
            Nenhum registro encontrado.
          </template>
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciamento de Insumos</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                                <i class="pi pi-search" />
                                <InputText @change="findAll(page)" v-model="pesquisar" placeholder="Pesquisar..." />
                            </span>
            </div>
          </template>
          <Column field="name" header="Nome" style="width:30%" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Nome</span>
              <span v-show="!skeletor">{{slotProps.data.name}}</span>
            </template>
          </Column>
          <Column field="provider" header="Marca">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Marca</span>
              <span v-show="!skeletor">{{slotProps.data.provider}}</span>
            </template>
          </Column>
          <Column field="amount" header="Quantidade">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Quantidade</span>
              <span v-show="!skeletor">{{slotProps.data.amount}}</span>
            </template>
          </Column>
          <Column field="metricsType" header="Métrica">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Métrica</span>
              <span v-show="!skeletor">{{slotProps.data.metricsType}}</span>
            </template>
          </Column>
          <Column>
            <template #body="slotProps" >
              <Button v-show="!skeletor" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edit(slotProps.data)" />
              <Button v-show="!skeletor" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Tem certeza de que deseja excluir <b>{{ product.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deletar(product.id)" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ProductService from "@/service/ProductService";

export default {
  data() {
    return {
      products: null,
      deleteProductDialog: false,
      filters: {},
      productService: null,
      product: null,
      totalRecords: 0,
      loading: false,
      skeletor: false,
      page: {
        originalEvent: {
          rows: 10,
          page: 0
        }
      },
      pesquisar: null
    }
  },
  created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    this.loading = true;
    await this.findAll(this.page);
    this.loading = false;
  },

  methods: {

    async findAll(event) {
      let response = null;
      this.skeletor = true;
      if(this.pesquisar) {
        response = await this.productService.findAll(event.originalEvent.rows, event.originalEvent.page, this.pesquisar);
      } else {
        response = await this.productService.findAll(event.originalEvent.rows, event.originalEvent.page);
      }
      if (response.status === 200 || response.status === 204) {
        this.products = response.data.content;
        this.totalRecords = response.data.totalElements;
      }
      this.skeletor = false;
    },
    edit(produto) {
      this.$router.push({name: 'produto', query: {id: produto.id}});
    },
    confirmDelete(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    async deletar(id) {
      this.loading = true;
      try {
        const response = await this.productService.delete(id);
        if (response.status === 200) {
          this.deleteProductDialog = false;
          this.contact = {};
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Produto removido com sucesso,',
            life: 3000
          });
          await this.findAll(this.page);
        }
      } catch(e) {
        this.deleteProductDialog = false;
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Produto não pode ser removido, pois possuí vinculo com outros dados,',
          life: 4000
        });
      }
      this.loading = false;
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-true {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-false {
    background: #FFCDD2;
    color: #C63737;
  }
}
</style>
